/*! purgecss start ignore */
@import './color.scss';
@import './animations.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins-Regular;
  color: $primary;
  height: 100%;
  width: 100%;
}

#__next {
  height: 100%;
  width: 100%;
  // overflow: hidden;
  .primary{
    color: $primary;
  }
  .a{
    color: #FEE4D8
  }
  .bor-primary{
    border-color: $primary;
  }
  .secondary{
    color: $secondary;
  }
  .gray {
    color: $gray;
  }
  .gray-1{
    color: $gray-1
  }
  .bg-gray-1 {
    background-color: $gray-1;
  }
  .gray-2{
    color: $gray-2
  }
  .gray-3{
    color: $gray-3
  }
  .bg-gray-3{
    background-color: $gray-3
  }
  .gray-4{
    color: $gray-4
  }
  .gray-5{
    color: $gray-5
  }
  .gray-6{
    color: $gray-6
  }
  .gray-7{
    color: $gray-7
  }
  .bor-gray-6{
    border-color: $gray-6
  }
  .bor-gray-5{
    border-color: $gray-5
  }
  .bg-gray-6{
    background-color: $gray-6
  }
  .yellow-1{
    color: $yellow-1
  }
  .red-1{
    color: $red-1;
  }
  .red-3{
    color: $red-3
  }
  .orange {
    color: $orange
  }
  .bg-orange {
    background-color: $orange;
  }
  .bg-orange-5{
    background-color: $orange-5;
  }
  .bg-yellow-1{
    background-color: $yellow-1;
  }
  .bg-yellow-2{
    background-color: $yellow-2;
  }
  .purple-1{
    color: $purple-1;
  }

  .purple-7{
    color: $purple-7;
  }

  .bg-purple-7{
    background-color: $purple-7;
  }

  .bg-purple-1{
    background-color: $purple-1;
  }

  .bg-yellow-3{
    background: $yellow-3;
  }

  .bg-yellow-4{
    background: $yellow-4;
  }

  .lh-16{
    line-height: 16px;
  }

  .lh-1-6{
    line-height: 1.6;
  }

  .blue{
    color: $blue;
  }

  .pink {
    color: $pink;
  }

  .bg-blue-6 {
    background-color: $blue-6; 
  }

  .gray-opac {
    color: $gray-opac;
  }

  .yellow-5 {
    color: $yellow-5;
  }

  .green {
    color: $green;
  }

  .orange-6 {
    color: $orange-6;
  }

  .bg-orange-6 {
    background-color: $orange-6;
  }

  .bor-gray-5 {
    border-color: $gray-5;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


@font-face {
  font-family: 'Poppins-Light';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  font-style: normal;
  src:  url('../public/cdn/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
}


@font-face {
  font-family: 'vquiz-icons';
  src: url('/cdn/fonts/vquiz-icons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "vquiz-icons";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-Clock:before { content: '\e800'; } /* '' */
.icon-CloseCross:before { content: '\e801'; } /* '' */
.icon-bars:before { content: '\e802'; } /* '' */
.icon-Heart:before { content: '\e803'; } /* '' */
.icon-Right:before { content: '\e804'; } /* '' */
.icon-ScreenCode:before { content: '\e805'; } /* '' */
.icon-meter:before { content: '\e806'; } /* '' */
.icon-Watch:before { content: '\e807'; } /* '' */
.icon-4Users:before { content: '\e809'; } /* '' */
.icon-VLogo:before { content: '\e80a'; vertical-align: text-bottom; font-size: 40px; } /* '' */
.icon-QuizLogo:before { content: '\e80b'; vertical-align: text-bottom; font-size: 70px; margin-left: -4px; margin-top: -31px; } /* '' */
.icon-Warning:before { content: '\e80d'; } /* '' */
.icon-Wrong:before { content: '\e80e'; } /* '' */
.icon-Cross:before { content: '\e80f'; } /* '' */
.icon-Share:before { content: '\e810'; } /* '' */
.icon-VedantuLogo:before { content: '\e812'; } /* '' */
.icon-Tick:before { content: '\e813'; } /* '' */

/*! purgecss end ignore */