.animate-shake {
  animation-name: shake;
  animation-duration: 0.15s;
  animation-iteration-count: 3;
  animation-direction: alternate;
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}
