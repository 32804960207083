$primary: #01202B;
$secondary: #51626A;

$gray: #344E5A;
$gray-1: #F8F8F8;
$gray-2: #5F747D;
$gray-3: #808F95;
$gray-4: #AFB9BD;
$gray-5: #DEE0E1;
$gray-6: #F0F0F0;
$gray-7: #344E5A;

$green: #0A9663;
$green-3: #11D18D;

$red-1: #E40000;
$red-3: #FF4A4A;

$yellow-1: #FAA706;
$yellow-2: #FFF0CB;
$yellow-3: #FFE8B5;
$yellow-4: #FEE4D8;
$yellow-5: #FEB701;

$orange: #FF693D;
$orange-5: #FEDCCC;
$orange-6: #FFF0E9;

$purple-1: #955CFF;
$purple-7: #F4EFFF;

$blue: #565DFF;
$blue-6: #CDDEFF;
$pink: #EC407A;
$gray-opac: rgba(1, 32, 43, 0.8);
$black-opac: rgba(0, 0, 0, 0.1);
